<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-06-04 15:52:35
 * @LastEditTime: 2020-06-05 17:08:56
-->
<template>
  <div class="Jepaas-promotion">
    <div class="promotion-div">
      <!-- <div class="text-status-center">
        <img
          src="@/pages/detail/assets/images/618/bannerTitle.svg"
          alt=""
        >
      </div> -->
      <!-- 主要内容 -->
      <div class="main-promotion">
        <div
          class="promotion-card"
          v-for="(data, _data) in arrDatas"
          :key="_data"
        >
          <el-card
            :class="_data> 0 && 'margin-top-150'"
            class="promotion-card-el"
            shadow="always"
          >
            <div class="promotion-every-main color-3F3F3F">
              <!-- 定位的内容---logo -->
              <div class="img_icon">
                <img
                  v-if="_data == 0"
                  style=""
                  src="@/pages/detail/assets/images/618/618Icon1.svg"
                  alt=""
                >
                <img
                  v-if="_data == 1"
                  style=""
                  src="@/pages/detail/assets/images/618/618Icon2.svg"
                  alt=""
                >
                <img
                  v-if="_data == 2"
                  style=""
                  src="@/pages/detail/assets/images/618/618Icon3.svg"
                  alt=""
                >
              </div>
              <!-- 定位---边框 -->
              <!-- <div class="main-border" /> -->
              <!-- 定位---头部方案 -->
              <div class="title-scheme">
                <span class="title-scheme-text">{{ data.title }}</span>
              </div>
              <!-- 定位左侧和右侧的小花 -->
              <div :class="_data % 2 == 0 && 'title-left-position' || 'title-right-position'">
                <img
                  src="@/pages/detail/assets/images/618/618Left.png"
                  alt=""
                >
              </div>
              <div class="title">
                <span class="font-size-18">原价：</span><span class="font-size-10 font-weight-600 color-A9ABA9">￥</span>
                <span class="color-A9ABA9 text-decoration-pro font-size-18 font-weight-600">{{ data.oldMoney }}</span><span class="font-size-10 margin-left-5">元</span>
                <span class="font-size-18 margin-left-40">活动价：</span><span class="font-size-12 font-weight-600 color-FF6F45">￥</span>
                <span class="font-size-30 color-FF6F45 font-weight-600">{{ data.newMoney }}</span><span class="font-size-12 font-weight-600 color-FF6F45 margin-left-5">元</span>
              </div>
              <div class="main margin-top-20">
                <el-row class="main-content font-size-18 color-3F3F3F">
                  <el-col
                    :span="7"
                    class="border-right-dis padding-TB-10"
                  >
                    <div class="padding-left-15">
                      {{ data.scheme.schemeTitle }}总计: <span class="color-409EFF font-weight-600">{{ data.scheme.money }}</span>元
                    </div>
                    <div class="padding-left-15 margin-top-20">
                      表数<span class="font-weight-600">{{ data.scheme.list.num }}</span>张: <span class="font-size-14 color-9C9A9A">（单价:{{ data.scheme.list.unitPrice }}元/小计:{{ data.scheme.list.countPrice }}元）</span>
                    </div>
                    <div class="padding-left-15 margin-top-20">
                      功能数<span class="font-weight-600">{{ data.scheme.func.num }}</span>个: <span class="font-size-14 color-9C9A9A">（单价:{{ data.scheme.func.unitPrice }}元/小计:{{ data.scheme.func.countPrice }}元）</span>
                    </div>
                    <div class="padding-left-15 margin-top-20">
                      流程数<span class="font-weight-600">{{ data.scheme.flow.num }}</span>个: <span class="font-size-14 color-9C9A9A">（单价:{{ data.scheme.flow.unitPrice }}元/小计:{{ data.scheme.flow.countPrice }}元）</span>
                    </div>
                    <div class="padding-left-15 margin-top-20">
                      用户数<span class="font-weight-600">{{ data.scheme.user.num }}</span>个: <span class="font-size-14 color-9C9A9A">（单价:{{ data.scheme.user.unitPrice }}元/小计:{{ data.scheme.user.countPrice }}元）</span>
                    </div>
                    <div class="margin-top-20 position-relative padding-left-15">
                      <i class="jeicon jeicon-heart-oo heartIcon" />
                      服务器数<span class="font-weight-600">1</span>台 官方<span class="font-size-20 color-FF6F45 font-weight-600">免费</span>赠送<span class="font-size-14 color-9C9A9A">（小计:0元）</span>
                    </div>
                  </el-col>
                  <el-col
                    :span="8"
                    :class="data.project.type == 1 && 'padding-left-80' || 'padding-left-30'"
                    class="border-right-dis text-status-left padding-TB-10"
                  >
                    <div>
                      JEPAAS-插件使用费: <span class="color-409EFF font-weight-600">{{ data.project.titleMoney }}</span>元
                    </div>
                    <el-row v-if="data.project.type == 2">
                      <el-col
                        v-for="(pro, _pro) in data.project.arrs"
                        :key="`_pro${_pro}}`"
                        :span="_pro < data.project.arrs.length - 2 && 12 || 24"
                        class="margin-top-20"
                      >
                        <i class="jeicon jeicon-work2-on work2-jeicon" />
                        {{ pro.text }} <span class="font-weight-600">{{ pro.money }}</span>元
                      </el-col>
                    </el-row>
                    <div v-else>
                      <div
                        v-for="(pro, _pro) in data.project.arrs"
                        :key="`_pro${_pro}}`"
                        class="margin-top-20"
                      >
                        <i class="jeicon jeicon-work2-on work2-jeicon" />
                        {{ pro.text }} <span class="font-weight-600">{{ pro.money }}</span>元
                      </div>
                    </div>
                  </el-col>
                  <el-col
                    :span="9"
                    class="padding-left-20 padding-top-30"
                  >
                    <div
                      style="z-index: 3"
                      class="text-status-center position-relative"
                    >
                      <i class="jeicon jeicon-crown-o crown-jeicon" />
                      <img
                        src="@/pages/detail/assets/images/618/618Vip.svg"
                        alt=""
                      >
                    </div>
                    <div class="margin-top-20 font-size-18 text-status-center font-weight-600">
                      JEPAAS-人工服务VIP服务-{{ data.service.month }}月: <span class="color-409EFF">{{ data.service.money }}</span>元
                    </div>
                    <div class="margin-top-40 text-status-center consultBtn">
                      <el-button
                        @click="openPhone"
                        type="primary"
                      >
                        立即咨询
                      </el-button>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <PhoneDialog
      ref="phoneDialog"
    />
  </div>
</template>

<script>
import PhoneDialog from '../../../detail/components/phone';

export default {
  name: 'Promotion',
  components: {
    PhoneDialog,
  },
  data() {
    return {
      arrDatas: [
        {
          title: '小型系统方案',
          oldMoney: '18875.00', // 原价
          newMoney: '11665.00', // 活动价
          scheme: {
            schemeTitle: '小型系统B方案',
            money: 4225,
            list: {
              num: 30,
              unitPrice: 20,
              countPrice: 600,
            },
            func: {
              num: 60,
              unitPrice: 29,
              countPrice: 1740,
            },
            flow: {
              num: 30,
              unitPrice: 47,
              countPrice: 1410,
            },
            user: {
              num: 100,
              unitPrice: 4.75,
              countPrice: 475,
            },
          }, // 方案内容
          project: {
            type: 1, // 展示方式
            titleMoney: '5350',
            arrs: [
              {
                text: '图报表引擎',
                money: 4000,
              },
              {
                text: '公共数据源',
                money: 350,
              },
              {
                text: '功能打包套件',
                money: 500,
              },
              {
                text: '数据流转策略',
                money: 300,
              },
              {
                text: '公共权限套件',
                money: 200,
              },
            ],
          }, // 项目方案
          service: {
            month: 3,
            money: 9300,
          },
        },
        {
          title: '中型系统方案',
          oldMoney: '58265.00', // 原价
          newMoney: '36008.00', // 活动价
          scheme: {
            schemeTitle: '小型系统B方案',
            money: 9315,
            list: {
              num: 100,
              unitPrice: 20,
              countPrice: 2000,
            },
            func: {
              num: 200,
              unitPrice: 25,
              countPrice: 5000,
            },
            flow: {
              num: 40,
              unitPrice: 46,
              countPrice: 1840,
            },
            user: {
              num: 100,
              unitPrice: 4.75,
              countPrice: 475,
            },
          }, // 方案内容
          project: {
            type: 2, // 展示方式
            titleMoney: '30950',
            arrs: [
              {
                text: '图报表引擎',
                money: 4000,
              },
              {
                text: 'JEAPP',
                money: '20000',
              },
              {
                text: '三方数据源',
                money: 2000,
              },
              {
                text: '数据流转策略',
                money: 300,
              },
              {
                text: '功能打包套件',
                money: 500,
              },
              {
                text: '公共数据源',
                money: 350,
              },
              {
                text: '钉钉,微信,飞书三方集成',
                money: 3600,
              },
              {
                text: '公共权限套件',
                money: 200,
              },
            ],
          }, // 项目方案
          service: {
            month: 6,
            money: '18000',
          },
        },
        {
          title: '大型系统方案',
          oldMoney: '65085.00', // 原价
          newMoney: '40223.00', // 活动价
          scheme: {
            schemeTitle: '小型系统B方案',
            money: '16135',
            list: {
              num: 200,
              unitPrice: 19,
              countPrice: 3800,
            },
            func: {
              num: 300,
              unitPrice: 24,
              countPrice: 7200,
            },
            flow: {
              num: 70,
              unitPrice: 43,
              countPrice: 3010,
            },
            user: {
              num: 500,
              unitPrice: 4.25,
              countPrice: 2125,
            },
          }, // 方案内容
          project: {
            type: 2, // 展示方式
            titleMoney: '30950',
            arrs: [
              {
                text: '图报表引擎',
                money: 4000,
              },
              {
                text: 'JEAPP',
                money: '20000',
              },
              {
                text: '三方数据源',
                money: 2000,
              },
              {
                text: '数据流转策略',
                money: 300,
              },
              {
                text: '功能打包套件',
                money: 500,
              },
              {
                text: '公共数据源',
                money: 350,
              },
              {
                text: '钉钉,微信,飞书三方集成',
                money: 3600,
              },
              {
                text: '公共权限套件',
                money: 200,
              },
            ],
          }, // 项目方案
          service: {
            month: 6,
            money: '18000',
          },
        },
      ],
    };
  },
  methods: {
    // 打开联系电话弹窗
    openPhone() {
      this.$refs.phoneDialog.phoneDialogVisible = true;
    },
  },
};
</script>
<style lang="less">
.Jepaas-promotion {
  .consultBtn {
    .el-button {
      width:280px;
      height:44px;
      background:linear-gradient(270deg,rgba(253,147,81,1) 0%,rgba(247,73,73,1) 100%);
      box-shadow:0px 2px 9px 0px rgba(0,0,0,0.15);
      border-radius:10px;
      color: white;
      font-size: 18px;
      border: none;
    }
  }
}
</style>
<style lang="less" scoped>
@import '../../style/shop/util';
.Jepaas-promotion {
  width: 100%;
  min-height: 100%;
  overflow: auto;
  box-sizing: border-box;
  .text-decoration-pro {
    text-decoration: line-through;
  }
  .border-right-dis {
    border-right: 2px dashed #D9D9D9;
  }
  .promotion-div {
    width: 100%;
    min-height: 100vh;
    background-image: url("../../../detail/assets/images/618/618banner1.jpeg");
    background-size: 1920px 100%;
    background-repeat: no-repeat;
    padding-top: 500px;
    padding-bottom: 150px;
    .main-promotion {
      width: 1400px;
      margin: 0 auto;
      .promotion-card-el {
        padding-bottom: 70px;
        overflow: inherit;
        height: 420px;
        box-sizing: border-box;
        border-radius: 10px;
        position: relative;
        .promotion-every-main {
          padding: 40px 0 0 30px;
          .title-left-position {
            position: absolute;
            left: -200px;
            top: 100px;
          }
          .title-right-position {
            position: absolute;
            right: -200px;
            top: 100px;
          }
          .main-content {
            height: 220px;
            .work2-jeicon {
              color: #A8C83D;
              font-size: 16px;
              // position: absolute;
              // left: -10px;
              // top: 4px;
              // vertical-align: middle;
            }
            .crown-jeicon {
              position: absolute;
              width: 100%;
              left: -15px;
              top: -8px;
              z-index: 2;
              color: #FFC421;
            }
            .heartIcon {
              color: #FA3239;
              font-size: 16px;
              position: absolute;
              left: -10px;
              top: 4px;
            }
          }
          .title-scheme {
            text-align: center;
            position: absolute;
            top: -100px;
            left: 50%;
            width: 560px;
            height: 120px;
            margin-left: -280px;
            background-image: url('../../../detail/assets/images/618/618Title.png');
            background-size: 100% auto;
            background-repeat: no-repeat;
            .title-scheme-text {
              position: absolute;
              width: 560px;
              height: 50px;
              margin-left: -280px;
              bottom: 5px;
              color: #8F7300;
              font-size: 24px;
              font-weight: 600;
            }
          }
          .main-border {
            position: absolute;
            top: 20px;
            left: 20px;
            width: 1400px;
            height: 440px;
            border: 2px solid white;
            border-radius: 10px;
          }
          .img_icon {
            width: 50px;
            height: 50px;
            position: absolute;
            top: -30px;
            left: 50px;
            z-index: 3;
          }
        }
      }
    }
  }
}
</style>
